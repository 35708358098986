<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="10" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-6">
                            <v-row align="center" justify="start">
                                <template v-for="chapter in test.chapters">
                                    <ChapterCard v-bind:chapter="chapter" v-bind:test="test" :key="chapter.id" />
                                </template>
                            </v-row>

                            <v-btn v-if="trainingIsFinished" href="https://leaseplan.vvcr-prodrive.nl" color="primary" class="mt-6 mb-2" block x-large>
                                <v-icon left>mdi-flag-checkered</v-icon> De training is voltooid, klik hier om terug te keren naar het trainingen portaal
                            </v-btn>
                        </v-card-text>

                        <v-card-actions v-if="!trainingIsFinished">
                            <v-btn text block color="text" href="https://leaseplan.vvcr-prodrive.nl" class="text--disabled font-italic hidden-sm-and-down">
                                Klik hier om terug te keren naar het trainingen portaal
                            </v-btn>
                            <v-btn text block color="text" href="https://leaseplan.vvcr-prodrive.nl" class="text--disabled font-italic hidden-md-and-up">
                                Naar het trainingen portaal
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="confirmResetTestDialog" max-width="340">
            <v-card>
                <v-card-title class="headline">Training overnieuw maken</v-card-title>
                <v-card-text>Als je de training overnieuw wilt maken zal de huidige voortgang worden gereset, weet je zeker dat je door wilt gaan?</v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="confirmResetTestDialog = false">Annuleren</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onSessionResetConfirm">Ja, resetten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import ChapterCard from './components/ChapterCard';
import TestService from './services/TestService';

export default {
    components: {
        HeaderImage,
        ChapterCard,
    },
    computed: {
        test() {
            return this.$store.state.test;
        },
        trainingIsFinished() {
            return this.$store.getters.trainingIsFinished;
        },
        player () {
            return this.$refs.plyr.player;
        },
        score () {
            let test = this.test;
            return Math.round(test.correctAnswerCount / test.questions.length * 100);
        }
    },
    watch: {
        test() {
            this.resizeCards();
        },
        isShowingThanksVideo (isShowing) {
            if (!isShowing) {
                this.player.pause();
            }
        }
    },
    methods: {
        onSessionResetConfirm() {
            this.confirmResetTestDialog = false;
            TestService.resetTest(this.test);
        },
        onFinishTrainingClick() {
            TestService.finishTest(this.test);
        },
        onResize() {
            if (!this.onResizeTimeout) {
                this.onResizeTimeout = setTimeout(() => {
                    this.resizeCards();
                    this.onResizeTimeout = null;
                }, 50);
            }
        },
        resizeCards() {
            let cardElements = document.getElementsByClassName('chapter-card');
            let cardSize,
                largestCardSize = 0;

            if (600 <= window.outerWidth) {
                for (let i = 0; i < cardElements.length; i++) {
                    cardSize = 0;
                    cardSize += cardElements[i].querySelector('.module-thumb').offsetHeight;
                    cardSize += cardElements[i].querySelector('.v-card__title').offsetHeight;
                    cardSize += cardElements[i].querySelector('.v-card__subtitle').offsetHeight;
                    // cardSize += cardElements[i].querySelector('.v-card__text').offsetHeight;

                    if (largestCardSize < cardSize) {
                        largestCardSize = cardSize;
                    }
                }
            }

            for (let i = 0; i < cardElements.length; i++) {
                cardElements[i].style.minHeight = largestCardSize + 'px';
            }
        },
    },
    data: () => ({
        confirmResetTestDialog: false,
        onResizeTimeout: null,
        resizeCardsSingleTimeout: null,
        resizeCardsInterval: null,
        isShowingThanksVideo: false,
    }),

    mounted: function () {
        window.addEventListener('resize', this.onResize);

        // Interval
        this.resizeCardsInterval = setInterval(() => {
            this.resizeCards();
        }, 1000);
        this.resizeCardsSingleTimeout = setTimeout(() => {
            this.resizeCards();
        }, 100);

        this.resizeCards();
    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.onResize);
        clearInterval(this.resizeCardsInterval);
        clearTimeout(this.resizeCardsSingleTimeout);
    },

    created() {
        this.resizeCards();
    },
};
</script>

<style>
.chapter i.v-icon.mdi-play-circle {
    color: #24568e !important;
}

span.duration-indication {
    position: absolute;
    right: 14px;
    bottom: 8px;
    font-size: 17px;
    opacity: 0.8;
    font-weight: 500;
}
span.duration-indication, span.duration-indication i.v-icon {
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(25, 25, 25, 0.9);
}
span.duration-indication i.v-icon {
    margin-top: -2px;
    font-size: 22px;
}

.test-finished-message {
    /*display: flex;*/
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    background: #fbfbfb;
    margin: 12px 0 10px;
    font-size: 16px;
    overflow: hidden;
}
.test-finished-message .v-image-container {
    padding-top: 0;
    padding-bottom: 0;
}
.test-finished-message .v-image {
    text-align: center;
    position: relative;
}
.test-finished-message .v-image .v-icon {
    font-size: 64px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
.test-finished-message .v-image:hover {
    cursor: pointer;
}
.test-finished-message .v-image:hover .v-icon {
    font-size: 72px;
}
.test-finished-message p {
    margin-bottom: 0;
}
.gauge-container {
    padding-right: 24px;
    padding-left: 0;
}
@media (max-width: 599px) {
    .test-finished-message p {
        padding-left: 12px;
    }
}
</style>
