<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-12">
                            <v-row align="center" justify="start" class="mt-2">
                                <h1>Welkom!</h1>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-2">
                                <p class="elektrisch-rijden">
                                    Welkom bij de training 'Elektrisch rijden' van LeasePlan. Elektrisch rijden is niet moeilijk, wel anders! Met deze training haal je het optimale rendement uit je elektrische auto en leer je handige tips. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="schadeoorzaken">
                                    Welkom bij de training 'top 3 schadeoorzaken' van LeasePlan. De training biedt tips en tricks die je kunnen helpen bij schade voorkomen. Het geeft inzicht in risico situaties en biedt handvatten om hierop te anticiperen. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="verkeersinzicht">
                                    Welkom bij de training 'verkeersinzicht' van LeasePlan. De training helpt bij ontwikkelen en anticiperen op verkeerssituaties en slim gebruik van moderne hulpsystemen. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="verkeersregels">
                                    Welkom bij de training 'Nieuwe verkeersregels' van LeasePlan. De training informeert je over de nieuwste verkeersregels en wanneer ze van toepassing zijn. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="afleiding-in-verkeer">
                                    Welkom bij de training 'afleiding in het verkeer' van LeasePlan. De training geeft je handvatten die jou in staat stellen om te gaan met afleidingen terwijl je deel neemt aan het verkeer. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="speedpedelec">
                                    Welkom bij de training 'e-bikes en speed pedelecs' van LeasePlan. Deze training geeft je tips over veilig rijden en ongelukken voorkomen met e-bikes en speed pedelecs. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="bestelauto">
                                    Welkom bij de training 'Rijden met een Bestelbus' van LeasePlan. In deze training leer je hoe je een 'pre-departure check' uitvoert en hoe je veilig vertrekt en aankomt op bestemming. Maar ook leer je hoe je met een bestelbus door bochten kunt manoeuvreren en hoe je defensief rijdt. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="hoezithetookalweer">
                                    Welkom bij de training 'Hoe zit het ook alweer' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="welkesnelheidgeldthier">
                                    Welkom bij de training 'Welke snelheid geldt hier' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="veiligenefficientonderweg">
                                    Welkom bij de training 'Veilig en efficient onderweg' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="gevaarherkenning">
                                    Welkom bij de training 'Gevaarherkenning' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="veiliginhalenenwaarnemen">
                                    Welkom bij de training 'Veilig inhalen en waarnemen' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="rijhulpsystemen">
                                    Welkom bij de training 'Rijhulpsystemen' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="voorrangverlenen">
                                    Welkom bij de training 'Voorrang verlenen' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="houdgevaaropafstand">
                                    Welkom bij de training 'Houd gevaar op afstand' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="hetgevaarvanautomatisme">
                                    Welkom bij de training 'Het gevaar van automatisme' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="schadevoorkomen">
                                    Welkom bij de training 'Schade voorkomen' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="hoegebruikjijjesmartphone">
                                    Welkom bij de training 'Hoe gebruik jij je smartphone' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="laatjijjeafleiden">
                                    Welkom bij de training 'Laat jij je afleiden' van LeasePlan. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                            </v-row>

                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-volume-high</v-icon>
                                    Zet je geluid aan! Kan dat niet via de luidsprekers? Gebruik dan een hoofdtelefoon. In de online training komen veel video’s voor waarin informatie wordt verteld.
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-clock-outline</v-icon>
                                    <span class="elektrisch-rijden">De totale duur van de online training is ongeveer 30 minuten, verdeeld over diverse hoofdstukken. </span>
                                    <span class="schadeoorzaken">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="verkeersinzicht">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="verkeersregels">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="afleiding-in-verkeer">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="speedpedelec">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="bestelauto">De totale duur van de online training is ongeveer 20 minuten.</span>
                                    <span class="hoezithetookalweer">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="welkesnelheidgeldthier">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="veiligenefficientonderweg">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="gevaarherkenning">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="veiliginhalenenwaarnemen">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="rijhulpsystemen">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="voorrangverlenen">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="houdgevaaropafstand">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="hetgevaarvanautomatisme">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="schadevoorkomen">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="hoegebruikjijjesmartphone">De totale duur van deze training is ongeveer 10 minuten.</span>
                                    <span class="laatjijjeafleiden">De totale duur van deze training is ongeveer 10 minuten.</span>
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-play-box</v-icon>
                                    Je kunt de video's pauzeren en opnieuw afspelen als je denkt dat je iets belangrijks hebt gemist.
                                </p>
                            </v-row>
<!--                            <v-row align="center" justify="start" class="mt-4">-->
<!--                                <p class="icon-left pr-20">-->
<!--                                    <v-icon>mdi-subtitles-outline</v-icon>-->
<!--                                    Wil je gebruik maken van Nederlandse ondertiteling? Dat kan! Klik in de videospeler rechts onderaan op het icoontje met CC er in en klik op Nederlands. Nu zijn de video’s te zien met ondertiteling.-->
<!--                                </p>-->
<!--                            </v-row>-->
                            <v-row align="center" justify="start" class="mt-4">
                                <p>
                                    Heb je vragen of opmerkingen n.a.v. deze training? Stuur ze dan naar <a href="mailto:customerservice@vvcr-prodrive.nl">customerservice@vvcr-prodrive.nl</a>.
                                </p>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="mt-4" v-if="firstSlide">
                            <v-btn x-large text block color="primary" :to="{ name: 'slide', params: { chapterId: firstSlide.chapter.id, slideId: firstSlide.id }}">
                                Verder <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';

export default {
    components: {
        HeaderImage,
    },

    computed: {
        firstSlide() {
            let test = this.$store.state.test;

            if (test && 1 <= test.slides.length) {
                return test.slides[0];
            }

            return false;
        },
    },
};
</script>
