const TrainingVariantContext = {
    trainingLabel: null,
    helprTrainingLabel: null,
    clientId: null,
    clientSecret: null,

    getTrainingLabel() {
        if (!this.trainingLabel) {
            if (/elektrisch-rijden/.test(window.location.hostname)) {
                this.trainingLabel = 'elektrisch-rijden';
            } else if (/schadeoorzaken/.test(window.location.hostname)) {
                this.trainingLabel = 'schadeoorzaken';
            } else if (/verkeersinzicht/.test(window.location.hostname)) {
                this.trainingLabel = 'verkeersinzicht';
            } else if (/verkeersregels/.test(window.location.hostname)) {
                this.trainingLabel = 'verkeersregels';
            } else if (/speedpedelec/.test(window.location.hostname)) {
                this.trainingLabel = 'speedpedelec';
            } else if (/afleiding/.test(window.location.hostname)) {
                this.trainingLabel = 'afleiding-in-verkeer';
            } else if (/bestelauto/.test(window.location.hostname)) {
                this.trainingLabel = 'bestelauto';
            } else if (/hoezithetookalweer/.test(window.location.hostname)) {
                this.trainingLabel = 'hoezithetookalweer';
            } else if (/welkesnelheidgeldthier/.test(window.location.hostname)) {
                this.trainingLabel = 'welkesnelheidgeldthier';
            } else if (/veiligenefficientonderweg/.test(window.location.hostname)) {
                this.trainingLabel = 'veiligenefficientonderweg';
            } else if (/gevaarherkenning/.test(window.location.hostname)) {
                this.trainingLabel = 'gevaarherkenning';
            } else if (/veiliginhalenenwaarnemen/.test(window.location.hostname)) {
                this.trainingLabel = 'veiliginhalenenwaarnemen';
            } else if (/rijhulpsystemen/.test(window.location.hostname)) {
                this.trainingLabel = 'rijhulpsystemen';
            } else if (/bedrijfswagens/.test(window.location.hostname)) {
                this.trainingLabel = 'bestelauto';
            } else if (/voorrangverlenen/.test(window.location.hostname)) {
                this.trainingLabel = 'voorrangverlenen';
            } else if (/houdgevaaropafstand/.test(window.location.hostname)) {
                this.trainingLabel = 'houdgevaaropafstand';
            } else if (/hetgevaarvanautomatisme/.test(window.location.hostname)) {
                this.trainingLabel = 'hetgevaarvanautomatisme';
            } else if (/schadevoorkomen/.test(window.location.hostname)) {
                this.trainingLabel = 'schadevoorkomen';
            } else if (/hoegebruikjijjesmartphone/.test(window.location.hostname)) {
                this.trainingLabel = 'hoegebruikjijjesmartphone';
            } else if (/laatjijjeafleiden/.test(window.location.hostname)) {
                this.trainingLabel = 'laatjijjeafleiden';
            } else {
                this.trainingLabel = 'elektrisch-rijden';
            }
        }

        return this.trainingLabel;
    },

    getHelprTrainingLabel() {
        if (!this.helprTrainingLabel) {
            switch (this.getTrainingLabel()) {
                case 'elektrisch-rijden':
                    this.helprTrainingLabel = 'evrijden_nl_nl';
                    break;
                case 'schadeoorzaken':
                    this.helprTrainingLabel = 'schade_voorkomen';
                    break;
                case 'verkeersinzicht':
                    this.helprTrainingLabel = 'verkeersinzicht';
                    break;
                case 'verkeersregels':
                    this.helprTrainingLabel = 'nieuweverkeersregels';
                    break;
                case 'speedpedelec':
                    this.helprTrainingLabel = 'speedpedelec';
                    break;
                case 'afleiding-in-verkeer':
                    this.helprTrainingLabel = 'afleidinginverkeer';
                    break;
                case 'bestelauto':
                    this.helprTrainingLabel = 'bedrijfswagens';
                    break;
                case 'hoezithetookalweer':
                    this.helprTrainingLabel = 'hoezithetookalweer';
                    break;
                case 'welkesnelheidgeldthier':
                    this.helprTrainingLabel = 'welkesnelheidgeldthier';
                    break;
                case 'veiligenefficientonderweg':
                    this.helprTrainingLabel = 'veiligenefficientonderweg';
                    break;
                case 'gevaarherkenning':
                    this.helprTrainingLabel = 'gevaarherkenning';
                    break;
                case 'veiliginhalenenwaarnemen':
                    this.helprTrainingLabel = 'veiliginhalenenwaarnemen';
                    break;
                case 'rijhulpsystemen':
                    this.helprTrainingLabel = 'rijhulpsystemen';
                    break;
                case 'voorrangverlenen':
                    this.helprTrainingLabel = 'voorrangverlenen';
                    break;
                case 'houdgevaaropafstand':
                    this.helprTrainingLabel = 'houdgevaaropafstand';
                    break;
                case 'hetgevaarvanautomatisme':
                    this.helprTrainingLabel = 'hetgevaarvanautomatisme';
                    break;
                case 'schadevoorkomen':
                    this.helprTrainingLabel = 'schadevoorkomen';
                    break;
                case 'hoegebruikjijjesmartphone':
                    this.helprTrainingLabel = 'hoegebruikjijjesmartphone';
                    break;
                case 'laatjijjeafleiden':
                    this.helprTrainingLabel = 'laatjijjeafleiden';
                    break;
            }
        }

        return this.helprTrainingLabel;
    },

    getBrowserTitle() {
        switch (this.trainingLabel) {
            case 'elektrisch-rijden':
                return 'Elektrisch rijden | LeasePlan';
            case 'schadeoorzaken':
                return 'Top 3 schadeoorzaken | LeasePlan';
            case 'verkeersinzicht':
                return 'Verkeersinzicht | LeasePlan';
            case 'verkeersregels':
                return 'Nieuwe verkeersregels | LeasePlan';
            case 'speedpedelec':
                return 'Speedpedelec | VVCR Prodrive';
            case 'afleiding-in-verkeer':
                return 'Afleiding in het verkeer | VVCR Prodrive';
            case 'bestelauto':
                return 'Bestelauto | VVCR Prodrive';
            case 'hoezithetookalweer':
                return 'Hoe zit het ook alweer | VVCR Prodrive';
            case 'welkesnelheidgeldthier':
                return 'Welke snelheid geldt hier | VVCR Prodrive';
            case 'veiligenefficientonderweg':
                return 'Veilig en efficient onderweg | VVCR Prodrive';
            case 'gevaarherkenning':
                return 'Gevaarherkenning | VVCR Prodrive';
            case 'veiliginhalenenwaarnemen':
                return 'Veilig inhalen en waarnemen | VVCR Prodrive';
            case 'rijhulpsystemen':
                return 'Rijhulpsystemen | VVCR Prodrive';
            case 'voorrangverlenen':
                return 'Voorrang verlenen | VVCR Prodrive';
            case 'houdgevaaropafstand':
                return 'Houd gevaar op afstand | VVCR Prodrive';
            case 'hetgevaarvanautomatisme':
                return 'Het gevaar van automatisme | VVCR Prodrive';
            case 'schadevoorkomen':
                return 'Schade voorkomen | VVCR Prodrive';
            case 'hoegebruikjijjesmartphone':
                return 'Hoe gebruik jij je smartphone | VVCR Prodrive';
            case 'laatjijjeafleiden':
                return 'Laat jij je afleiden | VVCR Prodrive';
        }
    },

    getClientId() {
        if (!this.clientId) {
            this._setClient();
        }

        return this.clientId;
    },

    getClientSecret () {
        if (!this.clientSecret) {
            this._setClient();
        }

        return this.clientSecret;
    },

    _setClient() {
        switch (this.getTrainingLabel()) {
            case 'elektrisch-rijden':
                this.clientId = '11_3lsmvs7z1pus084g004kkocgcwkscw88k4wcckg8kwwks0w84o';
                this.clientSecret = '5vwv2g9zuu8088kcgs0ww0ogswww4kccs0k00owww4gcgksw4s';
                break;
            case 'schadeoorzaken':
                this.clientId = '12_439cmhwptvqcowoksgows0cgkkgsk0gsskkcgcs4kso4kco8o4';
                this.clientSecret = '26g0xd9wyzfo4o80wgc4c0k4s4kw0ckc80g8ws8408owo4swcs';
                break;
            case 'verkeersinzicht':
                this.clientId = '13_2v3d6inpk3k00wgck0k4ggow44s08cg8c4w04wk8cg4wog0ws4';
                this.clientSecret = '2sz7m6uvw4g08gocwo0sk0w4sgk04wo40gg4wg0gcckc0csgw0';
                break;
            case 'verkeersregels':
                this.clientId = '14_3uxmal2jw2yo0g8oogc40ok4884w0sksg0k8owwcos4wooccgc';
                this.clientSecret = '5hl3aiy1ths80sso40wkw080ows0cs0kkw48sg0gsk4kg00ws0';
                break;
            case 'speedpedelec':
                this.clientId = '30_4oyadojlue80ssgcco8osggcs4kg8o48c0kcgsws0c0wocs8sw';
                this.clientSecret = '22bq4tfghehw844ggws08g88kcwgwsgocg0cg44sw4w004ccks';
                break;
            case 'afleiding-in-verkeer':
                this.clientId = '31_2y2qahh9ig2sowcw0s0wgw088ocwoowsk8wcgccccwwo0gskw8';
                this.clientSecret = '3ewsd9029ngggocckwo8skssgo484co404wocsgsk00ookgscg';
                break;
            case 'bestelauto':
                this.clientId = '48_4ofg85to9h4wgcw0okg0occko084kgsosog4gog4w80s8okgos';
                this.clientSecret = '3mx5hp9x6nswko04ksc088cgs4kcksc8ggc4kso0k4kk40c808';
                break;
            case 'hoezithetookalweer':
                this.clientId = '68_qkj6ohkhmf44kc8ck88o08gwwckc8kwws4kk0w04cg440s4ks';
                this.clientSecret = '3xu54o0u2fs4wow488k4c8css0oos04w0oow8ksgw00oskg4gg';
                break;
            case 'welkesnelheidgeldthier':
                this.clientId = '69_13k2ksxoel34w08ckwgswgw40sokowow8ksw4os4gggs0c4kwo';
                this.clientSecret = '4jmez2ba2ry80kgkocw8ck0c00o448cwk8ssws084s80wcsogo';
                break;
            case 'veiligenefficientonderweg':
                this.clientId = '70_2kcszrvs6xog08k4k88sg4g4cocc0wwwsgscg04wg88ggcc8s4';
                this.clientSecret = '1fn8hxfsxt40oskg8s4k0o0k0044skkogkw4o8ko4oc48w00w8';
                break;
            case 'gevaarherkenning':
                this.clientId = '71_3unfjjb4gjs40s444c084ccwc4wkwogkkc8ogog8okocssg044';
                this.clientSecret = '4i7ssyikyjacwwsc4wwsock4s4o80kgg0sccwckok8g4k84kwo';
                break;
            case 'veiliginhalenenwaarnemen':
                this.clientId = '72_2n09lhhrqbi84ogoow8wwoc0kkw0kswksskssgswwsgs4sww4w';
                this.clientSecret = '3sxr9xmavqqsgw4swo80ggkwwocsgks4wg4w8g0skscwc00wog';
                break;
            case 'rijhulpsystemen':
                this.clientId = '73_5lpz0vm3kusc0w0c4g04o0g04skwogow0c0o8kwgs4csowgok8';
                this.clientSecret = 'kcypf1umy9wk0sc0g08o4s0c4cw44cg04sgo4oggkswkos0wc';
                break;
            case 'voorrangverlenen':
                this.clientId = '75_369gen6v0lq8gkc4o0kwcckkowocgkwkw0wok8k8w8sc4wwcc4';
                this.clientSecret = '3bi8543jicu8sw8sww8g80g4ogkwkg8cw80kc8k4oos4oss0k4';
                break;
            case 'houdgevaaropafstand':
                this.clientId = '76_2oex9xobu2sksskc4ggo0gcww0ggoc4wccg44k08g84okwoosc';
                this.clientSecret = '4z57wg2s5v8cc4sw00gwccww8sgw0gs0skk4ww08488ggwo4s8';
                break;
            case 'hetgevaarvanautomatisme':
                this.clientId = '77_6bh25c8hfm88gsoc0sgk4csw84owws8ww0gcscgocggo8w8skw';
                this.clientSecret = '5txlhv01gnkssgos4c4os8ogw08s8gcwo8ckso4wos4ws08kcw';
                break;
            case 'schadevoorkomen':
                this.clientId = '78_1rh8fmylvli8o8wkgc0kcsw44cg4wcs84ws0000004wgc0k0go';
                this.clientSecret = '1azl2mx7ze688gc444c0w04sg88g0cs804c80048s0kkcgw004';
                break;
            case 'hoegebruikjijjesmartphone':
                this.clientId = '79_2qoz8g5eb50k00s4gcscwssgogo0gg4sskgcoswc0k400woow0';
                this.clientSecret = '531fv9qlxfokokwow08scco8kgo488kc8owkw8kckg8ogkcc8s';
                break;
            case 'laatjijjeafleiden':
                this.clientId = '80_195wsqjz8azos4gkskc8ogscwk88s808kc088wkwc40ws8404o';
                this.clientSecret = '67br4rk37p4wwgck4040co44848g0swk4ogsg4wo880sc84s44';
                break;
        }
    }
};

export default TrainingVariantContext
