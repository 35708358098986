import axios from 'axios'

const AxiosApi = axios.create({
    // baseURL: '//api.prodrive-elearning.defineweb.test',
    baseURL: '//api.prodrive-elearning.defineweb.nl',
    // baseURL: '//api.onlinetraining.prodriveacademy.nl',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

AxiosApi.interceptors.response.use(response => response, (error) => {
    if (401 === error.response.status) {
        window.location.replace('https://leaseplan.vvcr-prodrive.nl');
    }
    return Promise.reject(error);
});

export default AxiosApi
